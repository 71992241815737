export default {
	apiKey: "AIzaSyAoyU8HhtUATSgNBBkdNmgOS9qXhQ5p27g",
	authDomain: "nexthuman.firebaseapp.com",
	databaseURL: "https://nexthuman.firebaseio.com",
	projectId: "nexthuman",
	storageBucket: "nexthuman.appspot.com",
	messagingSenderId: "181576353324",
	appId: "1:181576353324:web:b2efa0ab2c98188b2a7389",
	measurementId: "G-JNZRZDDLNF"
};
