import React from 'react';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import Bg1 from '../img/about-bg-1.png';
import Bg2 from '../img/about-bg-2.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: 'auto',
    minHeight: 'calc(100vh - 130px)',
    paddingTop: 40,
    paddingBottom: 40,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 130px)',
      paddingTop: 60,
      paddingBottom: 60
    },
    ['@media (min-width: 780px)']: {
      minHeight: 'calc(100vh - 160px)'
    }
  },
  container: {
    maxWidth: 1440,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 40
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 160,
      paddingRight: 160
    }
  },
  aboutUsImg: {
    width: '100%',
    height: 'auto'
  },
  marginBottom40: {
    marginBottom: 24,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 40
    }
  },
  marginBottom24: {
    marginBottom: 24
  }
}));

export default () => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <Grid className={classes.root} container justify="center">
      <Grid item container className={classes.container}>
        <Grid item xs={12}>
          <Typography className={classes.marginBottom40} variant="h4" color="primary">
            About us
          </Typography>
        </Grid>
        <Grid item container spacing={4}>
          <Grid item sm={6} xs={12}>
            <img
              className={[classes.aboutUsImg, classes.marginBottom40].join(' ')}
              src={Bg1}
              alt="about bg1"
            />
            <Typography className={classes.marginBottom24} variant="h5" color="primary">
              Why a platform &amp; marketplace for participatory events?
            </Typography>
            <Typography variant="body2">
              <i>”…digital tools can, for example, generate a rich and dynamic market of cultural industries, 
                or concentrate the supply in the hands of very few players…”</i> UNESCO 2016<br />
              <br />
              In recent years, the commercial storytelling and marketing as we are used to has completely changed 
              shape. For example in fashion where you have clearly gone from a seasonal logic, ie a collection per 
              half year, with a marketing via traditional media, to a "drop logic" where you release unique products
               almost every day and where the marketing is about creating engagement on Instagram, FB or Youtube 
               with the help of collaborations, influencers and co-creation / co-design. The classic way of creating 
               brands is collapsing due to both retail platforms such as Amazon and social media. Those who succeed 
               now are some form of hyperbrands where the brand story becomes strong personal stories, contexts 
               where you as a consumer and partner (other brands) want to spend time with, actively follow, remix 
               and participate in. Companies that are good at this are often fashion brands , Rick Owens, Comme de 
               Garcons, Nike, Fila, Daniel Wellington etc.<br />
              <br />
              When we talk about the phenomenon above, we refer to the fact that the marketer has become a role-play 
              game masters who drives a story forward with thousands of other co-narrators via digital platforms.<br />
              <br />
              We want to take the new brand logic, the new commerciality, the strong personal stories, the "drop 
              and role-playing logic” to the culture via an marketplace, which can increase participation, engagement 
              and general spendings on cultural activities and immersive experiences. <br />
              <br />
              By being the partner in marketing and business development NextHuman.me will both increase margins and 
              being a community for the producers. Together we creates a much richer supply of exploratory experiences 
              for the participants.
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.marginBottom24} variant="h5" color="primary">
              NextHuman
            </Typography>
            <Typography className={classes.marginBottom40} variant="body2">
              Our mission is to deepen people’s understanding and co-creation of the world’s worlds’ 
              and spread ideas that matter and increase participation in exploring and creating these worlds’.<br />
              <br />
              Everybody has read books with worlds that you would love to walk into and explore. That is what
              NextHuman.me is about. Every NextHuman.me book opens a world that you can participate in.
              At a first glance the web app is a combination of an e-book app and an event-booking app. But the 
              books are in writing, and the stories are connected to real life events you can join. By participating 
              in an event connected to a story in a book, you become a character in that story. For every story 
              and event, you participate in, your character becomes more and more skilled.<br />
              <br />
              NextHuman.me is a platform and marketplace for participatory stories and events. It is also a platform 
              to help producers of participatory events and stories to increase their margins and the opportunity 
              to live on their passion by offer:<br />
              <br />
              <ul>
                <li>Easier to get fully booked events and increase number of fans. Because this is a platform where 
                  people who love participatory culture returns</li>
                <li>Professional digital marketing. Every day NextHuman.me works to increase the reach of the both 
                  specific events and the marketplace as a whole.</li>
                <li>We are constantly developing the platform to meet the needs and wishes of both participant and producers.</li>
                <li>We are producer’s partner in business development and search for new opportunities to charge, 
                  activate participants, automate parts of the offer, improve the service</li>
                <li>Offers train and education to become an even more skilled producer of participatory events and stories.</li>
              </ul>
              
            </Typography>
            <img className={classes.aboutUsImg} src={Bg2} alt="about bg2" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
