export default {
  EVENT_CREATED: 'EVENT_CREATED',
  SET_CHARACTERS: 'SET_CHARACTERS',
  ADD_CHARACTER: 'ADD_CHARACTER',
  REMOVE_CHARACTER: 'REMOVE_CHARACTER',
  SET_AUTHUSER: 'SET_AUTHUSER',
  SET_LASTSELECTEDCHARACTER: 'SET_LASTSELECTEDCHARACTER',
  UPDATE_CHARACTER: 'UPDATE_CHARACTER',
  DELETE_CHARACTER: 'DELETE_CHARACTER',
  ADD_TICKET: 'ADD_TICKET',
};
